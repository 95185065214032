import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import styles from "../components.module.sass";
import DesignerContext from "../../context/DesignerContext";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const PullingAdditionalInfo = () => {
  const { pullingInfo, onEditPullingInfo } = useContext(DesignerContext);
  const handleAddRecommendations = () => {
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        recomendations: [...prev.recomendations, ""],
      };
    });
  };

  const handleEditRecommendation = (index: number, value: string) => {
    const newRecommendations = pullingInfo.recomendations.map((recom, idx) => {
      if (index === idx) {
        return value;
      }
      return recom;
    });
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        recomendations: newRecommendations,
      };
    });
  };

  const handleRemoveRecommendation = (index: number) => {
    if (index || index === 0) {
      let newRecommendations = pullingInfo.recomendations.filter(
        (_, idx) => idx !== index
      );
      onEditPullingInfo((prev) => {
        return {
          ...prev,
          recomendations: newRecommendations,
        };
      });
    }
  };

  return (
    <>
      <div className={styles.buttonTally}>
        <ButtonGroup variant="outlined" orientation="horizontal">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleAddRecommendations()}
          >
            <NoteAltIcon fontSize="small" sx={{ mr: 1 }} />
            Add Recommendations
          </Button>
        </ButtonGroup>
      </div>
      {pullingInfo.recomendations.length > 0 && (
        <div className={styles.pullingForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography mt={2} variant="h6">
                Recommendations
              </Typography>
              <Divider />
            </Grid>
            {pullingInfo.recomendations.map((recommendation, index) => (
              <React.Fragment key={index}>
                <Grid item xs={1}>
                  <Tooltip title="Remove row">
                    <IconButton
                      size="small"
                      sx={{ width: "2rem" }}
                      onClick={() => handleRemoveRecommendation(index)}
                    >
                      <DeleteOutlineOutlinedIcon sx={{ color: "#CD1719" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    label="Recommendation"
                    variant="outlined"
                    multiline
                    value={recommendation}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      handleEditRecommendation(index, event.target.value)
                    }
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
};
