import React from "react";
import { PullingDesignProps } from "../interfaces/interfaces";
import { pulling_initial_state } from "../utils/data";

interface Props {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  pullingInfo: PullingDesignProps;
  pullingList: PullingDesignProps[];
  onEditPullingInfo: (value: React.SetStateAction<PullingDesignProps>) => void;
  fetchDataPulling: (id: number) => void;
  fetchPullingList: () => void;
  handleCreatePulling: () => void;
  handleEditPulling: (id: string) => void;
  handleDeletePulling: (id: string) => void;
}

const DesignerContext = React.createContext<Props>({
  isSuccess: false,
  isError: false,
  isLoading: false,
  pullingInfo: pulling_initial_state,
  pullingList: [],
  onEditPullingInfo: () => {},
  fetchDataPulling: () => {},
  fetchPullingList: () => {},
  handleCreatePulling: () => {},
  handleEditPulling: () => {},
  handleDeletePulling: () => {},
});

export default DesignerContext;
