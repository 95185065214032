import { useEffect, useState } from "react";
import DesignerContext from "./DesignerContext";
import { PullingDesignProps } from "../interfaces/interfaces";
import { pulling_initial_state } from "../utils/data";
import { useRequest } from "../hooks";
import { useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const DesignerProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { handleRequest } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [pullingInfo, setPullingInfo] = useState<PullingDesignProps>(
    pulling_initial_state
  );
  const [pullingList, setPullingList] = useState<PullingDesignProps[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 3000);
  }, [isSuccess, isError]);

  const fetchDataPulling = (id: number) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({ endpoint: `pulling/${id}`, options })
      .then((response) => {
        if (response.data) {
          setPullingInfo(response.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  const fetchPullingList = () => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({ endpoint: `pulling`, options })
      .then((response) => {
        if (response.data) {
          setPullingList(response.data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  const handleCreatePulling = () => {
    setIsLoading(true);
    if (pullingInfo.well.id !== 0) {
      let options: RequestInit = {
        method: "POST",
        body: JSON.stringify(pullingInfo),
      };
      handleRequest({ endpoint: `pulling`, options })
        .then((response) => {
          if (response.data) {
            setIsSuccess(true);
            response.data.id && navigate(`/pulling/${response.data.id}`);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  const handleEditPulling = (id: string) => {
    setIsLoading(true);
    if (pullingInfo.well.id !== 0) {
      let options: RequestInit = {
        method: "PUT",
        body: JSON.stringify(pullingInfo),
      };
      handleRequest({ endpoint: `pulling/${id}`, options })
        .then((response) => {
          if (response.data) {
            setIsSuccess(true);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  };

  const handleDeletePulling = (id: string) => {
    if (pullingInfo.well.id !== 0) {
      let options: RequestInit = {
        method: "DELETE",
      };
      handleRequest({ endpoint: `pulling/${id}`, options })
        .then((response) => {
          if (response.data) {
            setIsSuccess(true);
            setIsLoading(false);
            fetchPullingList();
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  };

  return (
    <DesignerContext.Provider
      value={{
        isSuccess,
        isError,
        isLoading,
        pullingInfo,
        pullingList,
        onEditPullingInfo: setPullingInfo,
        fetchDataPulling,
        fetchPullingList,
        handleCreatePulling,
        handleEditPulling,
        handleDeletePulling,
      }}
    >
      {children}
    </DesignerContext.Provider>
  );
};

export default DesignerProvider;
